@import "~antd/lib/style/themes/default.less";
// @import url("https://fonts.googleapis.com/css2?family=Anton&family=Roboto&display=swap");
body {
  margin: 0;
  padding: 0;
  // font-family: "Anton", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#root {
  height: 100%;
}
.ant-layout {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.login-form {
  min-width: 300px;
  max-width: 400px;
}
.login-form-forgot {
  float: right;
}
.login-form-button {
  width: 100%;
}

input,
.StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  max-width: 500px;
  padding: 10px 14px;
  font-size: 1em;
  font-family: "Source Code Pro", monospace;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: white;
}

input::placeholder {
  color: #aab7c4;
}

input:focus,
.StripeElement--focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.StripeElement.IdealBankElement,
.StripeElement.PaymentRequestButton {
  padding: 0;
}

.ant-radio-button-wrapper > .ant-radio-button {
  display: none;
}
// .ant-btn-primary {
//   background: linear-gradient(24deg, #00d0ff, #ff20f5) !important;
// }

.card {
  background: hsl(210, 13%, 17%);
  box-shadow: 4px 4px 8px 0px #00000040;
  padding: 2em;
  min-width: 250px;
  text-align: center;
  // margin: auto;
}

input {
  margin: 0;
}

html {
  scroll-behavior: smooth;
}
.reset-a,
.reset-a:hover,
.reset-a:visited,
.reset-a:focus,
.reset-a:active {
  text-decoration: none;
  color: inherit;
  outline: 0;
}

section:target > h3,
section:target > h2 {
  color: @primary-color;
}

section {
  margin-bottom: 3em;
}

.hidden {
  display: hidden;
  width: 0px;
  height: 0px;
  padding: 0px;
  margin: 0px;
  border: none;
}

.manualImage {
  max-width: 600px;
  margin: 32px;
}
h1.ant-typography,
.ant-typography h1 {
  color: #fff !important;
}

@primary-color: #4890eb;@body-background: hsl(210, 10%, 15%);@layout-header-background: hsl(210,10%, 13%);@menu-bg: hsl(210, 10%, 13%);@component-background: hsl(210,10%, 17%);@border-radius-base: 4px;@border-color-base: #fff;@input-placeholder-color: #ccc;@text-color: #fff;@menu-item-font-size: 20px;@screen-xl: 1600px;